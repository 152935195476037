import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import withLayout from "../../hoc/withLayout/withLayout";

import { formatMarkdown } from "../../utils/helpers";

import * as styles from "./TextPage.module.scss";
import { buildComponentStack } from "../../utils/StackBuilder/StackBuilder";

interface TextPageData {
  stack: any;
  primary: string;
  secondary: string;
  body: string;
}

interface TextPageProps {
  data: {
    alamedaapi: {
      textPage: TextPageData;
    };
  };
}

const TextPage = ({
  data: {
    alamedaapi: { textPage },
  },
}: TextPageProps) => {
  const componentStack = buildComponentStack(textPage.stack);

  return (
    <>
      <div className={styles.TextPage}>
        <div className="component-default-padding">
          <h1
            className={cx(
              "text-center text-2xl md:text-4xl lg:text-5xl",
              styles.title
            )}
          >
            {textPage.primary}
          </h1>
          <p className={cx("align-center subheading", styles.description)}>
            {textPage.secondary}
          </p>
          <div
            className={cx("break-words leading-tight", styles.textPageBody)}
            // TODO: we should wrap this in a component to do some sanitization
            dangerouslySetInnerHTML={{ __html: formatMarkdown(textPage.body) }}
          />
        </div>
      </div>
      <div>{componentStack}</div>
    </>
  );
};

export default withLayout(TextPage);

export const textPageQuery = graphql`
  query ($textPageId: ID!) {
    alamedaapi {
      textPage(id: $textPageId) {
        primary
        secondary
        body
        stack {
          __typename
          ... on AMAPI_ComponentStackSeo {
            ...ComponentStackSeoFields
          }
        }
      }
    }
  }
`;
